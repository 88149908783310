import React from "react"
import { graphql, PageProps } from "gatsby"
import { Helmet } from "react-helmet"

import {
  GatsbyLocationObjectType,
  courseType,
  courseBookingType,
} from "../types"
import {
  Loader,
  CheckoutBooking,
  Checkout,
  CheckoutSucceeded,
  MessageStrip,
} from "../components"

type Props = PageProps & {
  location: GatsbyLocationObjectType
  data: {
    allContentfulCourse: {
      edges: Array<{ node: courseType }>
    }
    allContentfulCourseBookings: {
      edges: Array<{ node: courseBookingType }>
    }
  }
}

const testingEnvironment = process.env.GATSBY_ENV === "dev"

export default ({ location, data }: Props) => {
  const [loading, setLoading] = React.useState(true)
  const [course, setCourse] = React.useState<{
    value: courseType | undefined
    errors: Array<string>
  }>({ value: undefined, errors: [] })
  const [booking, setBooking] = React.useState<{
    value: courseBookingType | undefined
    errors: Array<string>
  }>({ value: undefined, errors: [] })
  const [succeeded, setSucceeded] = React.useState(false)
  const [totalPrice, setTotalPrice] = React.useState(0)

  React.useEffect(() => {
    const findCourse = ({ courseId }: { courseId: string }) => {
      if (courseId) {
        const course = data.allContentfulCourse.edges.find(
          ({ node }) => node.contentful_id === courseId
        )
        if (course) {
          setCourse({ value: course.node, errors: [] })
        } else {
          setCourse({
            value: undefined,
            errors: ["Could not find course in all courses"],
          })
        }
      } else {
        setCourse((course) => ({
          value: undefined,
          errors: [...course.errors, "Could not find course in page URL"],
        }))
      }
    }

    const findBooking = ({ bookingId }: { bookingId: string }) => {
      if (bookingId) {
        const booking = data.allContentfulCourseBookings.edges.find(
          ({ node }) => node.contentful_id === bookingId
        )
        if (booking) {
          setBooking({
            value: booking.node,
            errors: [],
          })
          setTotalPrice(
            booking.node.discountedPrice
              ? booking.node.discountedPrice * 100
              : booking.node.price * 100
          )
        } else {
          setBooking({
            value: undefined,
            errors: ["Could not find booking in all bookings"],
          })
        }
      } else {
        setBooking((booking) => ({
          value: undefined,
          errors: [...booking.errors, "Could not find booking in page URL"],
        }))
      }
    }

    const setUpCheckoutVariables = async ({
      locationSearch,
    }: {
      locationSearch: string
    }) => {
      try {
        const queryParams = locationSearch.split("?course=")
        const queryParamValues = queryParams[1].split("&booking=")
        const courseId = queryParamValues[0]
        const bookingId = queryParamValues[1]

        findCourse({ courseId: courseId })
        findBooking({ bookingId: bookingId })
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    setUpCheckoutVariables({ locationSearch: location.search })

    return () => {
      setCourse({ value: undefined, errors: [] })
      setBooking({ value: undefined, errors: [] })
      setLoading(true)
      setSucceeded(false)
    }
  }, [])

  React.useEffect(() => {
    // @ts-ignore: Unreachable code error
    window.dataLayer = window.dataLayer || []
  }, [])

  return (
    <>
      {testingEnvironment ? (
        <Helmet title="Book Your Space | Breeze Academy">
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      ) : (
        <Helmet title="Book Your Space | Breeze Academy" />
      )}
      {loading || booking.errors.length > 0 || course.errors.length > 0 ? (
        <Loader
          errors={course.errors.concat(booking.errors)}
          content="Setting up checkout"
        />
      ) : course.value?.name &&
        booking.value?.startDate &&
        booking.value.endDate &&
        booking.value.addressFull &&
        booking.value.price &&
        booking.value.priceCurrency &&
        booking.value.stripePriceId ? (
        <>
          {!succeeded ? (
            <>
              <MessageStrip />
              <CheckoutBooking
                courseName={course.value.name}
                startDateTime={booking.value.startDate}
                endDateTime={booking.value.endDate}
                address={booking.value.addressFull}
                price={booking.value.price}
                totalPrice={totalPrice}
                priceCurrency={booking.value.priceCurrency}
              />
              <Checkout
                stripePriceId={
                  process.env.GATSBY_ENV === "dev"
                    ? "price_1Lx8hVKmEDHMdbEER78VHT3x"
                    : booking.value.stripePriceId
                }
                setSucceeded={setSucceeded}
                allowPayOver3Months={booking.value.allowPayOver3Months}
                allowPayOver5Months={booking.value.allowPayOver5Months}
                discountedPrice={booking.value.discountedPrice}
                price={booking.value.price}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                priceCurrency={booking.value.priceCurrency}
                courseId={course.value.contentful_id}
                courseName={course.value.name}
                startDateTime={booking.value.startDate}
                endDateTime={booking.value.endDate}
                bookingAddress={booking.value.addressShort}
                courseTopic={course.value.topic.name}
                bookingId={booking.value.contentful_id}
              />
            </>
          ) : (
            <CheckoutSucceeded course={course.value} booking={booking.value} />
          )}
        </>
      ) : (
        <Loader
          errors={[
            "Could not find booking ID or course ID to set up checkout, please go back and click 'Book now' again",
          ]}
          content="Setting up checkout"
        />
      )}
    </>
  )
}

export const pageQuery = graphql`
  query checkoutData {
    allContentfulCourse {
      edges {
        node {
          contentful_id
          name
          thinkificTraining {
            courseUrl
            duration
          }
          topic {
            name
          }
        }
      }
    }
    allContentfulCourseBookings(
      filter: { contentful_id: { ne: "3bBK7TnvlCI8JsnjF9Ma1m" } }
    ) {
      edges {
        node {
          contentful_id
          endDate
          addressShort
          addressFull
          price
          discountedPrice
          priceCurrency
          startDate
          stripePriceId
          allowPayOver3Months
          allowPayOver5Months
        }
      }
    }
  }
`
